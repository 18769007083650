import React, { useState } from 'react'
import swal from 'sweetalert';

const Number = ({ passBack }) => {
    const [number1, setNumber1] = useState(0);
    const [number2, setNumber2] = useState(0);
    const [number3, setNumber3] = useState(0);

    const numberWrapperStyle = {
        paddingTop: '5vh',
        display: 'flex',
        flexDirection: 'row',
        // height: '20vh',
        backgroundColor: '#a01323',
    }
    const numberStyle = {
        marginLeft: '40px',
        display: 'flex',
        flexDirection: 'column',
        flex: '1'
    }

    const USDTStyle = {
        display: 'flex',
        flex: '4',
        justifyContent: 'center',
        marginTop: '8.5vh',
        marginLeft: '20px'
    }

    const submitValue = () => {
        let sum = number1 * 100 + number2 * 10 + number3
        console.log(sum);
        if (sum <= 888 && sum >= 10) {
            passBack(sum)
        }
        else
            swal("失敗",`数量 ${sum} USDT 不合规定`)
    }

    return (
        <div className='NumberSection' style={{
            borderTop: '1px solid #efc75e',
            borderBottom: '1px solid #efc75e',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            backgroundColor: '#a01323',
            color: '#efc75e'
        }}>
            <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '6vh' }}>设定您要参与的金额</span>
            {/* <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '3vh' }}>(10-888 USDT)</span> */}
            <div className="NumberWrapper" style={numberWrapperStyle}>
                <div className='Number1' style={numberStyle}>
                    <div className='PlusButton' onClick={() => {
                        if (number1 <= 8)
                            setNumber1(number1 + 1)
                        else
                            setNumber1(0)
                    }}>
                        +
                    </div>
                    <div className='NumberInNumberJS'>{number1}</div>
                    <div className='MinusButton' onClick={() => {
                        if (number1 >= 1)
                            setNumber1(number1 - 1)
                        else
                            setNumber1(9)
                    }}>
                        -
                    </div>
                </div>
                <div className='Number2' style={numberStyle}>
                    <div className='PlusButton' onClick={() => {
                        if (number2 <= 8)
                            setNumber2(number2 + 1)
                        else
                            setNumber2(0)
                    }}>
                        +
                    </div>
                    <div className='NumberInNumberJS'>{number2}</div>
                    <div className='MinusButton' onClick={() => {
                        if (number2 >= 1)
                            setNumber2(number2 - 1)
                        else
                            setNumber2(9)
                    }}>
                        -
                    </div>
                </div>
                <div className='Number3' style={numberStyle}>
                    <div className='PlusButton' onClick={() => {
                        if (number3 <= 8)
                            setNumber3(number3 + 1)
                        else
                            setNumber3(0)
                    }}>
                        +
                    </div>
                    <div className='NumberInNumberJS'>{number3}</div>
                    <div className='MinusButton' onClick={() => {
                        if (number3 >= 1)
                            setNumber3(number3 - 1)
                        else
                            setNumber3(9)
                    }}>
                        -
                    </div>
                </div>
                <div className='textUSDT' style={USDTStyle}>
                    USDT
                </div>
            </div>
            <div>
                <button className='IDOJoinButton' onClick={submitValue}
                    style={{
                        cursor: 'pointer',
                        borderRadius: '20px',
                        width: '40vw',
                        height: '40px',
                        backgroundColor: '#efc75e',
                        color: '#a01323',
                        fontWeight: 'bold',
                        border: 'none',
                        margin: '28px',
                    }}> 参与 IDO </button>
            </div>
        </div>
    )
}

export default Number
