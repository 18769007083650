import React, { useState } from 'react';
import './Navbar.css';
import { IconContext } from 'react-icons';
import logoImage from '../image/tuLogo2.png'

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    //color: '#fff'
    <div>
      <IconContext.Provider value={{ color: '#efc75e' }}> 
        <div className="navbar">
          
        </div>
        <img src={logoImage} style={{
          width : '90px',
          position:'fixed',
          top: '10px',
          left : '40px',
          zIndex: '4',
        }}/>
      </IconContext.Provider>
    </div>
  )
}

export default Navbar

