import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'

const WalletConnect = ({ defaultAccountChange }) => {
    const [defaultAccount, setDefaultAccount] = useState(null)
    const [correctNetwork, setCorrectNetwork] = useState(null);
    const [connectButtonText, setConnectButtonText] = useState('Connect Wallet')

    useEffect(() => {
        changingAccount();
        defaultAccountChange(defaultAccount);
    }, [defaultAccount])

    async function changingAccount() {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', () => {
                connectWalletHandler()
            })
        }
    }

    const connectWalletHandler = async () => {
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(async (result) => {
                    await accountChangeHandler(result[0]);
                    setConnectButtonText(`${result[0].slice(0, 4)}...${result[0].slice(-4)}`);
                })
        } else {
            alert('Need to install MetaMask!')
        }
    }

    const accountChangeHandler = async (newAccount) => {
        checkCorrectNetwork();
        setDefaultAccount(newAccount);
    }

    const checkCorrectNetwork = async () => {
        const { ethereum } = window
        let chainId = await ethereum.request({ method: 'eth_chainId' })
        console.log('Connected to chain:' + chainId)

        const netWorkID = '0x42'

        if (chainId !== netWorkID) {
            setCorrectNetwork(false)
            console.log("Please Connect to the Correct Network")
        } else {
            setCorrectNetwork(true)
        }
    }

    return (
        <div className ="btnConnect">
            <button
                onClick={connectWalletHandler}
                className="buttonConnect"
                style={
                    {
                        position: 'fixed',
                        width:'120px',
                        height: '40px',
                        backgroundColor : 'transparent',
                        color :'white',
                        right : '10vw',
                        top : '3vh',
                        border :'1px solid white',
                        borderRadius : '20px',
                        zIndex: '3',
                    }
                }
            >{connectButtonText}</button>
        </div>
    )
}

export default WalletConnect
