import './App.css';
import { useState } from 'react';
import { ethers } from 'ethers';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Reports from './Pages/Reports';
import Products from './Pages/Products';
import WalletConnect from './components/WalletConnect';
import IDO from './Pages/IDO';
import Invitation from './Pages/Invitation';
import Mechanism from './Pages/Mechanism';
import Number from './components/Number';

let currentAccount = null;
// 初加载刷新
function App() {

  const [defaultAccount,setDefaultAccount] = useState(null)

  const handleAccountChange = (value) => {
    if (value === defaultAccount) return;
    if (value === null) return;
    setDefaultAccount(value)
  }
  return (

    <div className="App" id="bg">
      <Navbar />
      <WalletConnect defaultAccountChange={handleAccountChange} />
      <Home defaultAccount={defaultAccount}/>
    </div>

  );
}

export default App;
