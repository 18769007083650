import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import swal from 'sweetalert';

const Invitation = ({ defaultAccount }) => {

    const [copied, setCopied] = useState(false);
    const [defaultLink, setDefaultLink] = useState(null);
    let linkDNS = window.location.origin;
    let link = linkDNS.concat("/ido?inviter=")

    const generateLink = () => {
        if (defaultLink !== null) return;
        if (defaultAccount === null) return;
        let templink = link.concat(defaultAccount);
        setDefaultLink(templink);
    }

    const alertCopied = () => {
        swal("成功複製", `您的邀請連結 ${defaultLink} 已成功複製`)
    }

    generateLink()
    return (
        <div style={{
            width : '100vw',
            backgroundColor : '#a01323',
            fontSize: '18px',
            color: '#efc75e',
        }}>
            <div>
                <span>
                    <p style={{fontSize : '40px'}}>邀請連結</p>
                    <p style={{fontSize : '15px'}}>享 5% 返傭</p>
                </span>
                <span>
                    {
                        defaultLink === null &&
                        <div>
                            請先連結錢包
                        </div>
                    }
                    {
                        defaultLink !== null &&
                        <div style={{ wordWrap: 'break-word' }}>
                            您的邀請連結為<br />
                            {defaultLink}
                        </div>
                    }
                </span>
                <CopyToClipboard text={defaultLink} onCopy={() => setCopied(true)}>
                    <button id="inviteLink" onClick={alertCopied} style={{
                        cursor: 'pointer',
                        borderRadius: '20px',
                        width: '40vw',
                        height: '40px',
                        backgroundColor: '#efc75e',
                        color: '#a01323',
                        fontWeight: 'bold',
                        border: 'none',
                        margin: '28px',
                    }}>
                        Copy Invite Link
                    </button>
                </CopyToClipboard>
            </div>
            <div className='allLink' style={{
                backgroundColor: '#a01323',
                width: '100vw',
                fontSize: '18px',
                color: '#efc75e',
                borderBottom: '1px solid #efc75e',
            }}>
                <div className='title'>
                    您的邀請收益
                </div>
                <div className='content'>
                    $ 0
                </div>
                <div className='title'>
                    您邀請的人
                </div>
                <div className='content'>

                </div>
            </div>
        </div>
    )
}

export default Invitation
